import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  CircularProgress,
  Button,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import * as XLSX from "xlsx";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "@material-ui/lab/Pagination";

// ─────────────────────────────────────────────────────────────
// NÃO precisamos mais de: import { getReportData } from "./reportQueries";
// Agora tudo será feito via axios chamado ao backend.
// ─────────────────────────────────────────────────────────────

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#fff",
    padding: theme.spacing(3),
  },
  filterContainer: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    overflow: "auto",
    height: "60vh",
  },
  exportButton: {
    marginLeft: theme.spacing(2),
  },
  formControlSelect: {
    minWidth: 120,
    maxWidth: 180,
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const Reports3 = () => {
  const classes = useStyles();

  // Estados
  const [allData, setAllData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // Filtros
  const [filters, setFilters] = useState({
    atendente: "",
    cliente: "",
    status: "",
    tipo: "",
    origem: "",
    motivo: "",
    resgate: "",
    rankMin: 1,
    rankMax: 1000,
    dataInicio: moment().startOf("month").format("YYYY-MM-DD"),
    dataFim: moment().format("YYYY-MM-DD"),
  });

  /**
   * Função para buscar dados do backend via axios.
   * Presume que seu backend tenha rota: GET /api/reports3/filter
   * e aceite os filtros como query params.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // Montamos os query params a partir dos filtros:
      const params = {
        atendente: filters.atendente,
        cliente: filters.cliente,
        status: filters.status,
        tipo: filters.tipo,
        origem: filters.origem,
        motivo: filters.motivo,
        resgate: filters.resgate,
        rankMin: filters.rankMin,
        rankMax: filters.rankMax,
        dataInicio: filters.dataInicio,
        dataFim: filters.dataFim,
      };

      // Chama a rota do backend (ajuste a URL se necessário)
      // Exemplo: http://localhost:3001/api/reports3/filter
      const response = await axios.get("https://api.demo2.kamix.app/reports3/filter", { params });
      const data = response.data;

      if (Array.isArray(data)) {
        setAllData(data);
        setTotalRecords(data.length);
        paginateData(data, pageNumber, pageSize);
      } else {
        setAllData([]);
        setDataToDisplay([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      toast.error("Erro ao buscar dados: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Paginação local (front-end)
   */
  const paginateData = (data, page, size) => {
    const startIndex = (page - 1) * size;
    const paginatedData = data.slice(startIndex, startIndex + size);
    setDataToDisplay(paginatedData);
  };

  /**
   * Carrega dados iniciais ao montar o componente (useEffect com array vazio)
   */
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  /**
   * Sempre que mudar a página ou o tamanho de página, refaz a paginação local
   */
  useEffect(() => {
    paginateData(allData, pageNumber, pageSize);
  }, [pageNumber, pageSize, allData]);

  /**
   * Ao clicar em "Aplicar Filtro"
   */
  const handleApplyFilter = () => {
    setPageNumber(1);
    fetchData();
  };

  /**
   * Exportar para CSV usando XLSX
   */
  const exportToCSV = () => {
    // Apenas as colunas solicitadas
    const formattedData = allData.map((item) => ({
      "Conta": item["Conta"] || "",
      "ID Ticket": item["ID Ticket"] || "",
      "Atendente": item["Atendente"] || "",
      "Cliente": item["Cliente"] || "",
      "Nome Contato": item["Nome Contato"] || "",
      "Numero Contato": item["Numero Contato"] || "",
      "ID Contato": item["ID Contato"] || "",
      "Tempo Medio de Atendimento": item["Tempo Medio de Atendimento"] || "",
      "Tempo de Espera": item["Tempo de Espera"] || "",
      "Status": item["Status"] || "",
      "Inicio Atendimento": item["Inicio Atendimento"]
        ? moment(item["Inicio Atendimento"]).format("YYYY-MM-DD HH:mm:ss")
        : "",
      "Fim do Atendimento": item["Fim do Atendimento"]
        ? moment(item["Fim do Atendimento"]).format("YYYY-MM-DD HH:mm:ss")
        : "",
      "Entrou no sistema": item["Entrou no sistema"]
        ? moment(item["Entrou no sistema"]).format("YYYY-MM-DD HH:mm:ss")
        : "",
      "Ultima atualizacao": item["Ultima atualizacao"]
        ? moment(item["Ultima atualizacao"]).format("YYYY-MM-DD HH:mm:ss")
        : "",
      "TIPO": item["TIPO"] || "",
      "ORIGEM": item["ORIGEM"] || "",
      "MOTIVO": item["MOTIVO"] || "",
      "MEDICO": item["MEDICO"] || "",
      "MES": item["MES"] || "",
      "STATUS": item["STATUS"] || "",
      "RESGATE": item["RESGATE"] || "",
      "AGENDADO POR": item["AGENDADO POR"] || "",
      "OUTROS": item["OUTROS"] || "",
      "TMA": item["TMA"] || "",
      "TME": item["TME"] || "",
      "situacao_contato": item["situacao_contato"] || "",
      "Data de Entrada": item["Data de Entrada"]
        ? moment(item["Data de Entrada"]).format("YYYY-MM-DD HH:mm:ss")
        : "",
      "rank": item["rank"] || "",
    }));

    // Cria planilha e converte a CSV
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Relatorio_003");
    const csvData = XLSX.utils.sheet_to_csv(ws, { FS: ";" });
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const currentDateTime = moment().format("YYYYMMDDHHmm");
    const fileName = `relatorio_operacional_${currentDateTime}.csv`;

    // Força o download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /**
   * Muda quantidade de registros por página
   */
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNumber(1);
  };

  /**
   * Retorna faixa de registros exibida no momento
   */
  const getDisplayedRecordsRange = () => {
    const startRecord = (pageNumber - 1) * pageSize + 1;
    const endRecord = Math.min(pageNumber * pageSize, totalRecords);
    return `${startRecord}-${endRecord}`;
  };

  return (
    <div className={classes.mainContainer}>
      <h1>Relatório Operacional</h1>

      {/* FILTROS */}
      <Paper className={classes.filterContainer}>
        <Grid container spacing={2}>
          {/* Filtro: atendente */}
          <Grid item xs={12} md={3}>
            <TextField
              label="Atendente"
              variant="outlined"
              fullWidth
              value={filters.atendente}
              onChange={(e) =>
                setFilters({ ...filters, atendente: e.target.value })
              }
            />
          </Grid>

          {/* Filtro: cliente */}
          <Grid item xs={12} md={3}>
            <TextField
              label="Cliente"
              variant="outlined"
              fullWidth
              value={filters.cliente}
              onChange={(e) =>
                setFilters({ ...filters, cliente: e.target.value })
              }
            />
          </Grid>

          {/* Filtro: status */}
          <Grid item xs={12} md={3}>
            <TextField
              label="Status"
              variant="outlined"
              fullWidth
              value={filters.status}
              onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            />
          </Grid>

          {/* Filtro: TIPO */}
          <Grid item xs={12} md={3}>
            <TextField
              label="TIPO"
              variant="outlined"
              fullWidth
              value={filters.tipo}
              onChange={(e) => setFilters({ ...filters, tipo: e.target.value })}
            />
          </Grid>

          {/* Filtro: ORIGEM */}
          <Grid item xs={12} md={3}>
            <TextField
              label="ORIGEM"
              variant="outlined"
              fullWidth
              value={filters.origem}
              onChange={(e) =>
                setFilters({ ...filters, origem: e.target.value })
              }
            />
          </Grid>

          {/* Filtro: MOTIVO */}
          <Grid item xs={12} md={3}>
            <TextField
              label="MOTIVO"
              variant="outlined"
              fullWidth
              value={filters.motivo}
              onChange={(e) =>
                setFilters({ ...filters, motivo: e.target.value })
              }
            />
          </Grid>

          {/* Filtro: RESGATE */}
          <Grid item xs={12} md={3}>
            <TextField
              label="RESGATE"
              variant="outlined"
              fullWidth
              value={filters.resgate}
              onChange={(e) =>
                setFilters({ ...filters, resgate: e.target.value })
              }
            />
          </Grid>

          {/* Filtro: rank (intervalo) */}
          <Grid item xs={12} md={3} container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Rank Min"
                type="number"
                variant="outlined"
                fullWidth
                value={filters.rankMin}
                onChange={(e) =>
                  setFilters({ ...filters, rankMin: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Rank Max"
                type="number"
                variant="outlined"
                fullWidth
                value={filters.rankMax}
                onChange={(e) =>
                  setFilters({ ...filters, rankMax: e.target.value })
                }
              />
            </Grid>
          </Grid>

          {/* Filtro: dataInicio / dataFim ("Entrou no sistema") */}
          <Grid item xs={12} md={3}>
            <TextField
              label="Data Inicial"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={filters.dataInicio}
              onChange={(e) =>
                setFilters({ ...filters, dataInicio: e.target.value })
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              label="Data Final"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={filters.dataFim}
              onChange={(e) =>
                setFilters({ ...filters, dataFim: e.target.value })
              }
              fullWidth
            />
          </Grid>

          {/* Botões */}
          <Grid item xs={12} md={3} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyFilter}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Aplicar Filtro"}
            </Button>
            <Tooltip title="Exportar CSV">
              <IconButton className={classes.exportButton} onClick={exportToCSV}>
                <SaveAlt />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>

      {/* TABELA: exibe as colunas solicitadas */}
      <Paper className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Ticket</TableCell>
              <TableCell>Atendente</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Numero Contato</TableCell>
              <TableCell>ORIGEM</TableCell>
              <TableCell>MES</TableCell>
              <TableCell>RESGATE</TableCell>
              <TableCell>Data de Entrada</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToDisplay.length > 0 ? (
              dataToDisplay.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row["ID Ticket"]}</TableCell>
                  <TableCell>{row["Atendente"]}</TableCell>
                  <TableCell>{row["Cliente"]}</TableCell>
                  <TableCell>{row["Numero Contato"]}</TableCell>
                  <TableCell>{row["ORIGEM"]}</TableCell>
                  <TableCell>{row["MES"]}</TableCell>
                  <TableCell>{row["RESGATE"]}</TableCell>
                  <TableCell>
                    {row["Data de Entrada"]
                      ? moment(row["Data de Entrada"]).format("DD/MM/YYYY HH:mm")
                      : ""}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>Nenhum dado encontrado</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      {/* PAGINAÇÃO */}
      <div className={classes.paginationContainer}>
        <Typography>
          Total de registros: {getDisplayedRecordsRange()} de {totalRecords}
        </Typography>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControlSelect}
        >
          <InputLabel>Por página</InputLabel>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            label="Por página"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={Math.ceil(totalRecords / pageSize)}
          page={pageNumber}
          onChange={(event, value) => setPageNumber(value)}
        />
      </div>
    </div>
  );
};

export default Reports3;
